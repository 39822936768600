import { type RouteRecordRaw } from 'vue-router';
import type { RouteMeta } from '~operation/router/types';
import { PAGE_TITLE } from '~operation/constants/pageTitle';
import { isFeatUserLanguageSetting } from '~operation/utility/featFlg';
import {
  BULK_QUESTIONNAIRE_SETTING,
  BULK_QUESTIONNAIRE_SETTING_CREATE_FILE,
  CONFIRM_RESPONSE_STATUS,
  CONFIRM_RESULT,
  CONFIRM_SURVEY_DETAIL,
  SETTING_ADDITIONAL_QUESTIONS,
  SETTING_MAIL,
  SETTING_TARGET_AUDIENCE,
} from '~operation/static/links/helpPage';

export const surveyRoutes: RouteRecordRaw[] = [
  {
    // サーベイ詳細
    path: '/companies/:companyId/operation_cycles/:operationCycleId/surveys/:surveyId',
    name: 'survey_detail',
    component: () => import(/* webpackChunkName: "SurveyDetail" */ '~operation/views/operation/survey_detail/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      surveyId: Number(route.params.surveyId),
    }),
    meta: {
      title: PAGE_TITLE.SURVEY_DETAIL,
      helpBox: {
        manuals: [
          { title: 'サーベイ実施内容を確認する', url: CONFIRM_SURVEY_DETAIL },
          { title: '回答状況を確認する', url: CONFIRM_RESPONSE_STATUS },
          { title: '結果を確認する', url: CONFIRM_RESULT },
        ],
      },
    } as RouteMeta,
  },
  {
    // サーベイ/回答依頼メール
    path: '/companies/:companyId/operation_cycles/:operationCycleId/surveys/:surveyId/request_answer_mail',
    name: 'request_answer_mail',
    component: () => import(/* webpackChunkName: "RequestAnswerMail" */ '~operation/views/operation/request_answer_mail/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      surveyId: Number(route.params.surveyId),
    }),
    meta: {
      title: PAGE_TITLE.REQUEST_ANSWER_MAIL,
      helpBox: {
        manuals: [{ title: 'メールを設定する', url: SETTING_MAIL }],
      },
    } as RouteMeta,
  },
  {
    // サーベイ/回答促進メール
    path: '/companies/:companyId/operation_cycles/:operationCycleId/surveys/:surveyId/remind_answer_mail',
    name: 'remind_answer_mail',
    component: () => import(/* webpackChunkName: "RemindAnswerMail" */ '~operation/views/operation/remind_answer_mail/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      surveyId: Number(route.params.surveyId),
    }),
    meta: {
      title: PAGE_TITLE.REMIND_ANSWER_MAIL,
      helpBox: {
        manuals: [{ title: 'メールを設定する', url: SETTING_MAIL }],
      },
    } as RouteMeta,
  },
  {
    // 回答促進メール 送信一覧
    path: '/companies/:companyId/operation_cycles/:operationCycleId/surveys/:surveyId/remind_answer_mail_list',
    name: 'remind_answer_mail_list',
    component: () => import(/* webpackChunkName: "RemindAnswerMailList" */ '~operation/views/operation/remind_answer_mail_list/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      surveyId: Number(route.params.surveyId),
    }),
    meta: {
      title: PAGE_TITLE.REMIND_ANSWER_MAIL_LIST,
      helpBox: {
        manuals: [{ title: 'メールを設定する', url: SETTING_MAIL }],
      },
    } as RouteMeta,
  },
  {
    // 追加設問
    path: '/companies/:companyId/operation_cycles/:operationCycleId/surveys/:surveyId/additional_questions',
    name: 'additional_questions',
    component: () => import(/* webpackChunkName: "AdditionalQuestions" */ '~operation/views/operation/additional_questions/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      surveyId: Number(route.params.surveyId),
    }),
    meta: {
      title: PAGE_TITLE.ADDITIONAL_QUESTIONS,
      helpBox: {
        manuals: [{ title: '追加設問を設定する', url: SETTING_ADDITIONAL_QUESTIONS }],
      },
    } as RouteMeta,
  },
  {
    // サーベイ対象者・回答者の一括設定
    path: '/companies/:companyId/operation_cycles/:operationCycleId/surveys/:surveyId/bulk_questionnaire_setting',
    name: 'bulk_questionnaire_setting',
    component: () => import(/* webpackChunkName: "BulkQuestionnaireSetting" */ '~operation/views/operation/bulk_questionnaire_setting/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      surveyId: Number(route.params.surveyId),
    }),
    meta: {
      title: PAGE_TITLE.BULK_QUESTIONNAIRE_SETTING,
      helpBox: {
        manuals: [
          { title: '対象者・回答者を登録する', url: BULK_QUESTIONNAIRE_SETTING },
          { title: '対象者・回答者一覧ファイルのつくり方', url: BULK_QUESTIONNAIRE_SETTING_CREATE_FILE },
          { title: '閲覧対象者の設定方法', url: SETTING_TARGET_AUDIENCE },
        ],
      },
    } as RouteMeta,
  },
  {
    // サーベイ回答促進メール個別送信
    path: '/companies/:companyId/operation_cycles/:operationCycleId/surveys/:surveyId/resend-remind-mail',
    name: 'surveyResendRemindMail',
    component: () => import(/* webpackChunkName: "SurveyResendRemindMail" */ '~operation/views/operation/surveys/resendRemindMail/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      surveyId: Number(route.params.surveyId),
    }),
    meta: { title: PAGE_TITLE.SURVEY_RESEND_REMIND_MAIL } as RouteMeta,
  },
];

if (isFeatUserLanguageSetting.value) {
  surveyRoutes.push({
    // 追加設問翻訳
    path: '/companies/:companyId/operation_cycles/:operationCycleId/surveys/:surveyId/additional_questions/translation',
    name: 'additionalQuestionsTranslation',
    component: () => import('~operation/views/operation/additionalQuestionsTranslation/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      surveyId: Number(route.params.surveyId),
    }),
    meta: { title: PAGE_TITLE.ADDITIONAL_QUESTIONS_TRANSLATION } as RouteMeta,
  });
}
