import { HttpResponse, delay } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const get: GetResponseResolver = async () => {
  const questionnaireAnswerers = [];

  questionnaireAnswerers.push({
    companyUserId: 0,
    name: '長いユーザー名長いユーザー名',
    email: 'long.abcdefghijklmnopqrstuvwxyz0123456789.abcdefghijklmnopqrstuvwxyz0123456789.abcdefghijklmnopqrstuvwxyz0123456789@example.com',
    status: 'incomplete',
  });

  for (let n = 1; n < 101; n++) {
    questionnaireAnswerers.push({
      companyUserId: n,
      name: 'ユーザー' + n,
      email: `test${n}@example.com`,
      status: n % 2 === 0 ? 'incomplete' : 'complete',
    });
  }

  questionnaireAnswerers.push(
    {
      companyUserId: 1001,
      name: '岡田大輔',
      email: 'okada@example.com',
      status: 'incomplete',
    },
    {
      companyUserId: 1002,
      name: '亀山航太',
      email: 'kameyama@example.com',
      status: 'incomplete',
    },
    {
      companyUserId: 1003,
      name: '辰巳優斗',
      email: 'tatsumi@example.com',
      status: 'incomplete',
    }
  );

  await delay(300);

  return HttpResponse.json(
    {
      questionnaireAnswerers,
    },
    { status: 200 }
  );
};

export const surveyQuestionnaireAnswerers = {
  get,
};
