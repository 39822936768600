import { type RouteRecordRaw } from 'vue-router';
import type { RouteMeta } from '~operation/router/types';
import { PAGE_TITLE } from '~operation/constants/pageTitle';
import {
  BULK_STRETCH_PLAN_QUESTIONNAIRE_SETTING_CREATE_FILE,
  CONFIRM_STRETCH_PLAN_INPUT_STATUS,
  DECIDE_REVIEW_SETTING,
  SETTING_STRETCH_PLAN,
} from '~operation/static/links/helpPage';

export const stretchPlansRoutes: RouteRecordRaw[] = [
  {
    // ストレッチプラン詳細
    path: '/companies/:companyId/operation_cycles/:operationCycleId/stretch_plans/:stretchPlanId',
    name: 'stretch_plan_detail',
    component: () => import(/* webpackChunkName: "StretchPlanDetail" */ '~operation/views/operation/stretch_plan_detail/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      stretchPlanId: Number(route.params.stretchPlanId),
    }),
    meta: {
      title: PAGE_TITLE.STRETCH_PLAN_DETAIL,
      helpBox: {
        manuals: [
          { title: 'ストレッチプランの実施設定をする', url: SETTING_STRETCH_PLAN },
          { title: '振り返り設定を決める手順', url: DECIDE_REVIEW_SETTING },
          { title: 'ストレッチプランの入力状況を確認する', url: CONFIRM_STRETCH_PLAN_INPUT_STATUS },
        ],
      },
    } as RouteMeta,
  },
  {
    // ストレッチプラン/登録依頼メール設定
    path: '/companies/:companyId/operation_cycles/:operationCycleId/stretch_plans/:stretchPlanId/request_mail',
    name: 'stretch_plan_request_mail',
    component: () => import(/* webpackChunkName: "StretchPlanRequestMail" */ '~operation/views/operation/stretch_plans/request_mail/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      stretchPlanId: Number(route.params.stretchPlanId),
    }),
    meta: {
      title: PAGE_TITLE.STRETCH_PLAN_REQUEST_MAIL,
      helpBox: {
        manuals: [{ title: 'ストレッチプランの実施設定をする', url: SETTING_STRETCH_PLAN }],
      },
    } as RouteMeta,
  },
  {
    // ストレッチプラン/登録促進メール設定
    path: '/companies/:companyId/operation_cycles/:operationCycleId/stretch_plans/:stretchPlanId/remind_mail',
    name: 'stretch_plan_remind_mail',
    component: () => import(/* webpackChunkName: "StretchPlanRemindMail" */ '~operation/views/operation/stretch_plans/remind_mail/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      stretchPlanId: Number(route.params.stretchPlanId),
    }),
    meta: {
      title: PAGE_TITLE.STRETCH_PLAN_REMIND_MAIL,
      helpBox: {
        manuals: [{ title: 'ストレッチプランの実施設定をする', url: SETTING_STRETCH_PLAN }],
      },
    } as RouteMeta,
  },
  {
    // ストレッチプラン/登録促進メール 送信一覧
    path: '/companies/:companyId/operation_cycles/:operationCycleId/stretch_plans/:stretchPlanId/remind_mail_list',
    name: 'remind_mail_list',
    component: () => import(/* webpackChunkName: "RemindMailList" */ '~operation/views/operation/remind_mail_list/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      stretchPlanId: Number(route.params.stretchPlanId),
    }),
    meta: { title: PAGE_TITLE.REMIND_MAIL_LIST } as RouteMeta,
  },
  {
    // ストレッチプラン/振り返り入力依頼メール
    path: '/companies/:companyId/operation_cycles/:operationCycleId/stretch_plans/:stretchPlanId/progress_request_mail',
    name: 'stretch_plan_progress_request_mail',
    component: () =>
      import(/* webpackChunkName: "StretchPlanProgressRequestMail" */ '~operation/views/operation/stretch_plans/progress_request_mail/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      stretchPlanId: Number(route.params.stretchPlanId),
    }),
    meta: {
      title: PAGE_TITLE.STRETCH_PLAN_PROGRESS_REQUEST_MAIL,
      helpBox: {
        manuals: [{ title: 'ストレッチプランの実施設定をする', url: SETTING_STRETCH_PLAN }],
      },
    } as RouteMeta,
  },
  {
    // ストレッチプラン/振り返り入力促進メール
    path: '/companies/:companyId/operation_cycles/:operationCycleId/stretch_plans/:stretchPlanId/progress_remind_mail',
    name: 'stretch_plan_progress_remind_mail',
    component: () =>
      import(/* webpackChunkName: "StretchPlanProgressRemindMail" */ '~operation/views/operation/stretch_plans/progress_remind_mail/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      stretchPlanId: Number(route.params.stretchPlanId),
    }),
    meta: {
      title: PAGE_TITLE.STRETCH_PLAN_PROGRESS_REMIND_MAIL,
      helpBox: {
        manuals: [{ title: 'ストレッチプランの実施設定をする', url: SETTING_STRETCH_PLAN }],
      },
    } as RouteMeta,
  },
  {
    // ストレッチプラン対象者の一括設定
    path: '/companies/:companyId/operation_cycles/:operationCycleId/stretch_plans/:stretchPlanId/bulk_stretch_plan_settings',
    name: 'bulk_stretch_plan_settings',
    component: () => import(/* webpackChunkName: "BulkStretchPlanSettings" */ '~operation/views/operation/bulk_stretch_plan_settings/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      stretchPlanId: Number(route.params.stretchPlanId),
    }),
    meta: {
      title: PAGE_TITLE.BULK_STRETCH_PLAN_SETTINGS,
      helpBox: {
        manuals: [{ title: 'ストレッチプラン対象者一覧ファイルのつくり方', url: BULK_STRETCH_PLAN_QUESTIONNAIRE_SETTING_CREATE_FILE }],
      },
    } as RouteMeta,
  },
  {
    // ストレッチプラン/振り返り依頼メール 送信一覧
    path: '/companies/:companyId/operation_cycles/:operationCycleId/stretch_plans/:stretchPlanId/progress_input_mail_list',
    name: 'stretch_plan_progress_input_mail_list',
    component: () =>
      import(
        /* webpackChunkName: "StretchPlanProgressInputMailList" */ '~operation/views/operation/stretch_plans/progress_input_mail_list/index.vue'
      ),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      stretchPlanId: Number(route.params.stretchPlanId),
    }),
    meta: {
      title: PAGE_TITLE.STRETCH_PLAN_PROGRESS_INPUT_MAIL_LIST,
    } as RouteMeta,
  },
  {
    // ストレッチプラン登録促進メール個別送信
    path: '/companies/:companyId/operation_cycles/:operationCycleId/stretch_plans/:stretchPlanId/resend-remind-mail',
    name: 'stretchPlanResendRemindMail',
    component: () =>
      import(/* webpackChunkName: "StretchPlanResendRemindMail" */ '~operation/views/operation/stretch_plans/resendRemindMail/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      stretchPlanId: Number(route.params.stretchPlanId),
    }),
    meta: {
      title: PAGE_TITLE.STRETCH_PLAN_RESEND_REMIND_MAIL,
    } as RouteMeta,
  },
];
