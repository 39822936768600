import { type RouteRecordRaw } from 'vue-router';
import type { RouteMeta } from '~operation/router/types';
import { PAGE_TITLE } from '~operation/constants/pageTitle';
import { CREATE_OPERATION_CYCLE } from '~operation/static/links/helpPage';

export const operationCyclesRoutes: RouteRecordRaw[] = [
  {
    // 運用設定詳細
    path: '/companies/:companyId/operation_cycles/:operationCycleId(\\d+)',
    name: 'operation_cycle_detail',
    component: () => import(/* webpackChunkName: "OperationCycleDetail" */ '~operation/views/operation/operation_cycle_detail/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
    }),
    meta: {
      requiresAuth: true,
      title: PAGE_TITLE.OPERATION_CYCLE_DETAIL,
    } as RouteMeta,
  },
  {
    // 運用設定新規
    path: '/companies/:companyId/operation_cycles/create',
    name: 'create_operation_cycle',
    component: () => import(/* webpackChunkName: "CreateOperationCycle" */ '~operation/views/operation/create_operation_cycle/index.vue'),
    props: (route) => ({ companyId: Number(route.params.companyId) }),
    meta: {
      title: PAGE_TITLE.OPERATION_CYCLE_CREATE,
      helpBox: {
        manuals: [{ title: '運用設定を登録する', url: CREATE_OPERATION_CYCLE }],
      },
    } as RouteMeta,
  },
  {
    // 運用設定編集
    path: '/companies/:companyId/operation_cycles/:operationCycleId/edit',
    name: 'edit_operation_cycle',
    component: () => import(/* webpackChunkName: "EditOperationCycle" */ '~operation/views/operation/edit_operation_cycle/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
    }),
    meta: {
      title: PAGE_TITLE.OPERATION_CYCLE_EDIT,
    } as RouteMeta,
  },
];
