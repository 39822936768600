import { type RouteRecordRaw } from 'vue-router';
import type { RouteMeta } from '~operation/router/types';
import { PAGE_TITLE } from '~operation/constants/pageTitle';
import { BULK_TRAINING_QUESTIONNAIRE_SETTING, BULK_TRAINING_QUESTIONNAIRE_SETTING_CREATE_FILE } from '~operation/static/links/helpPage';

export const trainingsRoutes: RouteRecordRaw[] = [
  {
    // 研修詳細
    path: '/companies/:companyId/operation_cycles/:operationCycleId/trainings/:trainingId',
    name: 'training_detail',
    component: () => import(/* webpackChunkName: "TrainingDetail" */ '~operation/views/operation/training_detail/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      trainingId: Number(route.params.trainingId),
    }),
    meta: {
      title: PAGE_TITLE.TRAINING_DETAIL,
      helpBox: {
        manuals: [
          { title: '研修参加者を設定する', url: BULK_TRAINING_QUESTIONNAIRE_SETTING },
          { title: '研修参加者一覧ファイルのつくり方', url: BULK_TRAINING_QUESTIONNAIRE_SETTING_CREATE_FILE },
        ],
      },
    } as RouteMeta,
  },
  {
    // 研修参加者の一括設定
    path: '/companies/:companyId/operation_cycles/:operationCycleId/trainings/:trainingId/bulk_training_settings',
    name: 'bulk_training_settings',
    component: () => import(/* webpackChunkName: "BulkTrainingSettings" */ '~operation/views/operation/bulk_training_settings/index.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      trainingId: Number(route.params.trainingId),
    }),
    meta: { title: PAGE_TITLE.BULK_TRAINING_SETTINGS } as RouteMeta,
  },
];
